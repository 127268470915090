import React from "react";

export default function Loader() {
  return (
    <div class="loading">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  );
}
