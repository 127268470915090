import ax from "axios";
import { BASE_URL } from "../constants/DataConstants";
import jsPDF from "jspdf";
import * as moment from "moment";
import html2canvas from "html2canvas";

const axios = ax.create({ baseURL: BASE_URL });

export const loginUser = async (data) => {
  let config = {
    method: "post",
    url: `orginfo/login`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const resetPassword = async (data) => {
  let config = {
    method: "post",
    url: `orginfo/resetPassword`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const salesEntry = async (data) => {
  let config = {
    method: "post",
    url: `sales/saleEntry`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const getEntries = async (data) => {
  let config = {
    method: "post",
    url: `sales/getAllEntries`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const getExcelData = async (data) => {
  let config = {
    method: "post",
    url: `sales/exportToExcel`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const updateEntry = async (data) => {
  let config = {
    method: "put",
    url: `sales/updateEntry`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const deleteEntry = async (data) => {
  let config = {
    method: "post",
    url: `sales/deleteEntry`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const addParty = async (data) => {
  let config = {
    method: "post",
    url: `party/addParty`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await axios.request(config);
  return response.data;
};

export const getAllParty = async () => {
  let config = {
    method: "get",
    url: `party/getAllParty`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  const response = await axios.request(config);
  return response.data;
};

export const deleteParty = async (data) => {
  let config = {
    method: "delete",
    url: `party/deleteParty/${data.partyId}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  const response = await axios.request(config);
  return response.data;
};

// get dyanamic image from remote api  add to canvas to display in pdf
const getImageData = async (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      resolve(canvas.toDataURL("image/jpeg"));
    };
    img.src = src;
  });
};

export const generatePdf = async (data) => {
  try {
    var doc = new jsPDF("portrait", "pt", "a4", "false");
    // A4 Page size in point width=595 x height=842
    //Important Site for study
    // https://mrrio.github.io/jsPDF/examples/basic.html

    //doc.setFontSize(22);
    doc.text("INVOICE", 298, 30, "center");
    doc.line(575, 40, 20, 40);

    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(moment(data.date).format("DD/MM/YYYY"), 565, 65, "right");
    doc.setFont(undefined, "normal");
    doc.text("Date : ", 510, 65, "right");

    doc.setFontSize(10);
    doc.text("Party Name : ", 30, 65);
    doc.setFont(undefined, "bold");
    doc.text(data.partyName, 150, 65);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Party Location : ", 30, 85);
    doc.setFont(undefined, "bold");
    doc.text(data.partyLocation, 150, 85);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Vehicle No : ", 30, 105);
    doc.setFont(undefined, "bold");
    doc.text(data.vehicleNo, 150, 105);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Driver No : ", 30, 125);
    doc.setFont(undefined, "bold");
    doc.text(data.driverNo, 150, 125);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Cement Type : ", 30, 145);
    doc.setFont(undefined, "bold");
    doc.text(data.cementType, 150, 145);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Company Rate : ", 30, 165);
    doc.setFont(undefined, "bold");
    doc.text(data.companyRate.toString(), 150, 165);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Party Rate (W.B) : ", 30, 185);
    doc.setFont(undefined, "bold");
    doc.text(data.partyRateWB.toString(), 150, 185);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Party Rate (I.B) : ", 30, 205);
    doc.setFont(undefined, "bold");
    doc.text(data.partyRateIB.toString(), 150, 205);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Requirement MT / Bags : ", 30, 225);
    doc.setFont(undefined, "bold");
    doc.text(data.mtOrBags.toString(), 150, 225);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Old Pending : ", 30, 245);
    doc.setFont(undefined, "bold");
    doc.text(data.oldPending.toString(), 150, 245);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Total Amount : ", 30, 265);
    doc.setFont(undefined, "bold");
    doc.text(data.totalAmount.toString(), 150, 265);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Paid Amount : ", 30, 285);
    doc.setFont(undefined, "bold");
    doc.text(data.paid.toString(), 150, 285);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Payment Mode : ", 30, 305);
    doc.setFont(undefined, "bold");
    doc.text(data.paymentMode.toString(), 150, 305);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Total Pending : ", 30, 325);
    doc.setFont(undefined, "bold");
    doc.text(data.totalPending.toString(), 150, 325);

    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    doc.text("Remark : ", 30, 345);
    doc.setFont(undefined, "bold");
    doc.text(data.remark, 150, 345);

    // // get remote image and add to pdf
    // const dyanamicImg = await getImageData(
    //   "https://api.lingayatvadhuvar.in/development/public/storage/images/users/1704093821.jpg"
    // );
    // doc.addImage(dyanamicImg, "JPEG", 20, 20, 200, 200);
    // // end image code

    doc.line(575, 365, 20, 365);
    let today = new Date();
    doc.save(
      data.partyName + " " + moment(today).format("DD/MM/YYYY") + " Receipt.pdf"
    );
    doc.autoPrint(); // <<--------------------- !!
    // doc.output("dataurlnewwindow");
    window.open(doc.output("bloburl"), "_blank");
  } catch (error) {
    console.log(error);
  }
};
