import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../comman/Loader";
import { resetPassword } from "../../services/APIService";
import { ADMIN_DATA } from "../../constants/DataConstants";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      data.contact = ADMIN_DATA.contact;
      setIsLoading(true);
      const response = await resetPassword(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="row no-margin-padding">
        <div className="col-md-6">
          <h3 className="block-title">Reset Password</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-area-2 proclinic-box-shadow">
              <h3 className="widget-title">Reset Password</h3>
              {showMsg && (
                <div
                  className={msgClass}
                  //className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{msgText}</strong>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label for="patient-name">Current Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Current Password"
                      {...register("currentPass", {
                        required: "Enter current password",
                      })}
                    />
                    {errors.currentPass && (
                      <span className="text-danger float-left">
                        {errors.currentPass.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label for="patient-name">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      {...register("newPass", {
                        required: "Enter new password",
                      })}
                    />
                    {errors.newPass && (
                      <span className="text-danger float-left">
                        {errors.newPass.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label for="patient-name">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      {...register("confirmPass", {
                        required: "Enter confirm password",
                        validate: (value) =>
                          value === getValues("newPass") ||
                          "Password does not match.",
                      })}
                    />
                    {errors.confirmPass && (
                      <span className="text-danger float-left">
                        {errors.confirmPass.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
