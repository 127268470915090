/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";

export default function Admin() {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  return (
    <div className="wrapper">
      <nav id="sidebar" className="proclinic-bg">
        <div className="sidebar-header">
          <a href="/admin">
            <img src="images/logo.png" className="logo" alt="logo" />
          </a>
        </div>
        <ul className="list-unstyled components">
          {/* <li>
            <a href="tables.html">
              <span className="ti-home"></span> Dashboard
            </a>
          </li> */}
          <li className={location.pathname === "/admin" && "active"}>
            <Link to="/admin">
              <span className="ti-layout-menu-v"></span> Sales
            </Link>
          </li>
          <li className={location.pathname === "/admin/reports" && "active"}>
            <Link to="/admin/reports">
              <span className="ti-file"></span> View Reports
            </Link>
          </li>

          <li className={location.pathname === "/admin/parties" && "active"}>
            <Link to="/admin/parties">
              <span className="ti-menu"></span> Party List
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin/reset-password" && "active"
            }
          >
            <Link to="/admin/reset-password">
              <span className="ti-key"></span> Reset Password
            </Link>
          </li>

          <li>
            <a
              data-toggle="modal"
              data-target="#logoutModal"
              href="javascript:void(0)"
            >
              <span className="ti-power-off"></span> Logout
            </a>
          </li>
        </ul>
      </nav>
      <div id="content">
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="responsive-logo">
              <a href="/admin">
                <img src="images/logo.png" className="logo" alt="logo" />
              </a>
            </div>
            <ul className="nav">
              {/* <li className="nav-item">
                <span className="ti-menu" id="sidebarCollapse"></span>
              </li> */}

              <li className="nav-item">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="ti-user"></span>
                </a>
                <div className="dropdown-menu proclinic-box-shadow2 profile animated flipInY">
                  <h5>Welcome Admin</h5>
                  <Link className="dropdown-item" to={"/admin"}>
                    <span className="ti-layout-menu-v"></span> Sales
                  </Link>
                  <Link className="dropdown-item" to={"/admin/reports"}>
                    <span className="ti-file"></span> View Reports
                  </Link>
                  <Link className="dropdown-item" to={"/admin/parties"}>
                    <span className="ti-file"></span> Party List
                  </Link>
                  <Link className="dropdown-item" to={"/admin/reset-password"}>
                    <span className="ti-key"></span> Reset Password
                  </Link>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <span className="ti-power-off"></span> Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <Outlet />
      </div>
      <div
        className="modal fade proclinic-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        id="logoutModal"
      >
        <div className="modal-dialog modal-lorvens">
          <div className="modal-content proclinic-box-shadow2">
            <div className="modal-header">
              <h5 className="modal-title">Would you like to logout ?</h5>
              <span
                className="ti-close"
                data-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <button
                    onClick={logout}
                    type="button"
                    className="btn btn-lorvens proclinic-bg"
                  >
                    Logout
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
