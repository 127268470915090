import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../comman/Loader";
import {
  salesEntry,
  updateEntry,
  addParty,
  getAllParty,
  generatePdf,
} from "../../services/APIService";
import { EDIT_SALES } from "../../constants/DataConstants";

export default function Sales() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [title, setTitle] = useState();
  const [partyData, setPartyData] = useState();
  const [selectedParty, setSelectedParty] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm();

  useEffect(() => {
    settings();
  }, []);

  const settings = async () => {
    await loadAllParties();
    if (EDIT_SALES) {
      setTitle("Edit Sales Entry");
      reset(EDIT_SALES);
    } else {
      setTitle("New Sales Entry");
    }
  };

  const loadAllParties = async () => {
    try {
      setIsLoading(true);
      var response = await getAllParty();
      setPartyData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    if (EDIT_SALES) {
      updateSalesEntry(data);
    } else {
      newSalesEntry(data);
    }
  };

  const calculateTotal = () => {
    if (parseFloat(getValues("partyRateWB")) > 0) {
    } else {
      setValue("partyRateWB", 0);
    }
    if (parseFloat(getValues("partyRateIB")) > 0) {
    } else {
      setValue("partyRateIB", 0);
    }
    if (parseFloat(getValues("mtOrBags")) > 0) {
    } else {
      setValue("mtOrBags", 0);
    }
    if (parseFloat(getValues("oldPending")) > 0) {
    } else {
      setValue("oldPending", 0);
    }
    if (parseFloat(getValues("totalAmount")) > 0) {
    } else {
      setValue("totalAmount", 0);
    }
    if (parseFloat(getValues("paid")) > 0) {
    } else {
      setValue("paid", 0);
    }
    if (parseFloat(getValues("totalPending")) > 0) {
    } else {
      setValue("totalPending", 0);
    }

    if (parseFloat(getValues("partyRateWB")) > 0) {
      setValue(
        "totalAmount",
        parseInt(getValues("partyRateWB") * getValues("mtOrBags")) +
          parseInt(getValues("oldPending"))
      );
      setValue(
        "totalPending",
        parseInt(getValues("totalAmount") - getValues("paid"))
      );
    } else if (parseFloat(getValues("partyRateIB")) > 0) {
      setValue(
        "totalAmount",
        parseInt(getValues("partyRateIB") * getValues("mtOrBags")) +
          parseInt(getValues("oldPending"))
      );
      setValue(
        "totalPending",
        parseInt(getValues("totalAmount") - getValues("paid"))
      );
    } else {
      setValue("partyRateWB", 0);
      setValue("partyRateIB", 0);
      setValue("mtOrBags", 0);
      setValue("totalAmount", parseInt(getValues("oldPending")));
      setValue("paid", 0);
      setValue("totalPending", parseInt(getValues("totalAmount")));
    }
  };

  const newSalesEntry = async (data) => {
    try {
      setIsLoading(true);
      const response = await salesEntry(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        data.partyName = selectedParty[0]?.name;
        generatePdf(data);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      reset();
      setIsLoading(false);
      localStorage.removeItem("EditSalesEntry");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  const updateSalesEntry = async (data) => {
    try {
      setIsLoading(true);
      const response = await updateEntry(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        generatePdf(data);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      reset();
      setIsLoading(false);
      localStorage.removeItem("EditSalesEntry");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  const cancelEdit = () => {
    localStorage.removeItem("EditSalesEntry");
    window.location.reload();
  };

  const addNewParty = async (data) => {
    try {
      setIsLoading(true);
      const response = await addParty(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      reset1();
      loadAllParties();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  const getPartyName = (e) => {
    const val = partyData.filter((item) => item.partyId == e.target.value);
    setSelectedParty(val);
    console.log(val);
    setValue("oldPending", val[0].pending);
    calculateTotal();
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="row no-margin-padding">
        <div className="col-md-6">
          <h3 className="block-title">{title}</h3>
        </div>
        {/* <div className="col-md-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <span className="ti-home"></span>
              </a>
            </li>
            <li className="breadcrumb-item">Patients</li>
            <li className="breadcrumb-item active">Add Patient</li>
          </ol>
        </div> */}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-area-2 proclinic-box-shadow">
              <h3 className="widget-title">{title}</h3>
              {showMsg && (
                <div
                  className={msgClass}
                  //className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{msgText}</strong>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label for="patient-name">Party Name</label>
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Party Name"
                      {...register("partyId", {
                        required: "Enter party name",
                      })}
                      onChange={(e) => getPartyName(e)}
                    >
                      <option value="">Select Party</option>
                      {partyData &&
                        partyData.map((item, i) => (
                          <option key={i} value={item.partyId}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {errors.partyId && (
                      <span className="text-danger float-left">
                        {errors.partyId.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-2 my-auto">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      data-toggle="modal"
                      data-target="#partyModal"
                    >
                      Add New
                    </button>
                  </div>
                  <div className="form-group col-md-3">
                    <label for="dob">Date</label>
                    <input
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      {...register("date", {
                        required: "Select date",
                      })}
                    />
                    {errors.date && (
                      <span className="text-danger float-left">
                        {errors.date.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="age">Party Location</label>
                    <input
                      type="text"
                      placeholder="Party Location"
                      className="form-control"
                      {...register("partyLocation", {
                        required: "Enter party location",
                      })}
                    />
                    {errors.partyLocation && (
                      <span className="text-danger float-left">
                        {errors.partyLocation.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label for="phone">Vehicle No</label>
                    <input
                      type="text"
                      placeholder="Vehicle No"
                      className="form-control"
                      {...register("vehicleNo", {
                        required: "Enter vehicle no",
                      })}
                    />
                    {errors.vehicleNo && (
                      <span className="text-danger float-left">
                        {errors.vehicleNo.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label for="phone">Driver No</label>
                    <input
                      type="text"
                      placeholder="Driver No"
                      className="form-control"
                      {...register("driverNo", {
                        required: "Enter driver no",
                      })}
                    />
                    {errors.driverNo && (
                      <span className="text-danger float-left">
                        {errors.driverNo.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="patient-name">Cement Type</label>
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Select Type"
                      {...register("cementType", {
                        required: "Select cement type",
                      })}
                    >
                      <option value="">Select Type</option>
                      <option value="PPC">PPC</option>
                      <option value="OPC">OPC</option>
                      <option value="SUPER">SUPER</option>
                    </select>
                    {errors.cementType && (
                      <span className="text-danger float-left">
                        {errors.cementType.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Company Rate</label>
                    <input
                      type="text"
                      placeholder="Company Rate"
                      className="form-control"
                      {...register("companyRate", {
                        required: "Enter company rate",
                      })}
                    />
                    {errors.companyRate && (
                      <span className="text-danger float-left">
                        {errors.companyRate.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Party Rate(W.B)</label>
                    <input
                      type="text"
                      placeholder="Party Rate"
                      className="form-control"
                      {...register("partyRateWB")}
                      onKeyUp={calculateTotal}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Party Rate(I.B)</label>
                    <input
                      type="text"
                      placeholder="Party Rate"
                      className="form-control"
                      {...register("partyRateIB")}
                      onKeyUp={calculateTotal}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Req. MT / Bags</label>
                    <input
                      type="text"
                      placeholder="Req. MT / Bags"
                      className="form-control"
                      {...register("mtOrBags", {
                        required: "Enter requirement",
                      })}
                      onKeyUp={calculateTotal}
                    />
                    {errors.mtOrBags && (
                      <span className="text-danger float-left">
                        {errors.mtOrBags.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Old Pending</label>
                    <input
                      type="text"
                      readOnly
                      placeholder="Old pending"
                      className="form-control"
                      {...register("oldPending", {
                        required: "Old pending",
                      })}
                    />
                    {errors.oldPending && (
                      <span className="text-danger float-left">
                        {errors.oldPending.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Total Amount</label>
                    <input
                      type="text"
                      readOnly
                      placeholder="Total Amount"
                      className="form-control"
                      {...register("totalAmount", {
                        required: "Enter total amount",
                      })}
                    />
                    {errors.totalAmount && (
                      <span className="text-danger float-left">
                        {errors.totalAmount.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Paid Amount</label>
                    <input
                      type="text"
                      placeholder="Paid amount"
                      className="form-control"
                      {...register("paid", {
                        required: "Enter paid amount",
                      })}
                      onKeyUp={calculateTotal}
                    />
                    {errors.paid && (
                      <span className="text-danger float-left">
                        {errors.paid.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="patient-name">Payment Mode</label>
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Select payment mode"
                      {...register("paymentMode", {
                        // required: "Select payment mode",
                        validate: (value) =>
                          parseInt(getValues("paid")) === 0 ||
                          "Select payment mode.",
                      })}
                    >
                      <option value="">Payment Mode</option>
                      <option value="Online">Online</option>
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                    </select>
                    {errors.paymentMode && (
                      <span className="text-danger float-left">
                        {errors.paymentMode.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label for="phone">Total Pending</label>
                    <input
                      type="text"
                      readOnly
                      placeholder="Total pending"
                      className="form-control"
                      {...register("totalPending", {
                        required: "Enter total pending",
                      })}
                    />
                    {errors.paid && (
                      <span className="text-danger float-left">
                        {errors.paid.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-9">
                    <label for="phone">Remark</label>
                    <input
                      type="text"
                      placeholder="Remark"
                      className="form-control"
                      {...register("remark")}
                    />
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg mr-3"
                    >
                      Submit
                    </button>

                    <button
                      type="button"
                      onClick={cancelEdit}
                      className="btn btn-secondary btn-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade proclinic-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        id="partyModal"
      >
        <div className="modal-dialog modal-lorvens">
          <div className="modal-content proclinic-box-shadow2">
            <div className="modal-header">
              <h5 className="modal-title">Add new party name</h5>
              <span
                className="ti-close"
                data-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div className="form-group col-md-12">
                    <label for="age">Party Name</label>
                    <input
                      type="text"
                      placeholder="Party name"
                      className="form-control"
                      {...register1("name", {
                        required: "Enter party name",
                      })}
                    />
                    {errors1.name && (
                      <span className="text-danger float-left">
                        {errors1.name.message}
                      </span>
                    )}
                  </div>
                  <button
                    onClick={handleSubmit1(addNewParty)}
                    type="button"
                    className="btn btn-lorvens proclinic-bg"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
