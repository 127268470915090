import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../comman/Loader";
import { getAllParty, deleteParty } from "../../services/APIService";

export default function Parties() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [partyData, setPartyData] = useState();
  const [deleteData, setDeleteData] = useState();

  useEffect(() => {
    loadAllParties();
  }, []);

  const loadAllParties = async () => {
    try {
      setIsLoading(true);
      var response = await getAllParty();
      setPartyData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onDelete = (item) => {
    setDeleteData(item);
  };

  const deleteRecord = async () => {
    try {
      setIsLoading(true);
      const response = await deleteParty(deleteData);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        loadAllParties();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="row no-margin-padding">
        <div className="col-md-6">
          <h3 className="block-title">All Party</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-area-2 proclinic-box-shadow">
              <h3 className="widget-title">All Party</h3>
              {showMsg && (
                <div
                  className={msgClass}
                  //className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{msgText}</strong>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}

              <div className="widget-area-2 proclinic-box-shadow my-3">
                {/* <h3 className="widget-title">All Party</h3> */}
                {partyData?.length > 0 ? (
                  <div className="table-div">
                    <div className="table-responsive">
                      <div className="scrollme">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Sr.No</th>
                              <th scope="col">Party Name</th>
                              <th scope="col">Pending</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partyData &&
                              partyData.map((item, i) => (
                                <tr key={i}>
                                  <th scope="row">{i + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.pending}</td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-outline-danger mb-0"
                                      onClick={() => onDelete(item)}
                                      data-toggle="modal"
                                      data-target="#deletePartyModal"
                                    >
                                      <span class="ti-close"></span> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h6 className="my-3">No record found..!</h6>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade proclinic-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        id="deletePartyModal"
      >
        <div className="modal-dialog modal-lorvens">
          <div className="modal-content proclinic-box-shadow2">
            <div className="modal-header">
              <h5 className="modal-title">
                Do you want to delete this record ?
              </h5>
              <span
                className="ti-close"
                data-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <h6>
                    Party Name : <b>{deleteData?.name}</b>{" "}
                  </h6>
                  <h6>
                    Pending Amount : <b>{deleteData?.pending}</b>{" "}
                  </h6>

                  <div class="modal-footer">
                    <button
                      onClick={deleteRecord}
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-lorvens proclinic-bg"
                      class="btn btn-primary"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
