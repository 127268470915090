import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "../components/site/Login";
import Admin from "../components/admin/Admin";
import Home from "../components/admin/Home";
import Sales from "../components/admin/Sales";
import Reports from "../components/admin/Reports";
import ResetPassword from "../components/admin/ResetPassword";
import Parties from "../components/admin/Parties";

import { ADMIN_DATA } from "../constants/DataConstants";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Login />} />

        <Route
          path="admin"
          element={ADMIN_DATA !== null ? <Admin /> : <Navigate to="/" />}
        >
          <Route path="" element={<Sales />} />
          <Route path="sales" element={<Sales />} />
          <Route path="reports" element={<Reports />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="parties" element={<Parties />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
