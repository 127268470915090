//export const BASE_URL = "http://localhost:5000/cement-api/";
export const BASE_URL = "https://debugsolutions.co.in/cement-api/";
export const PHONEPE_PLESK_URL = "https://sndlmsapi.atopd.in/api";
export const IMAGE_BASE_URL = "https://api.lingayatvadhuvar.in/development/";

export const TURF_ADDRESS =
  "B50-51, Ganesh Nagar B, Pratap Nagar, Via 160 Feet Road, Sauganer. Landmark - Near Coaching Hub. Jaipur -302033";
export const MOBILE_ONE = "+91 829 090 8396";
export const MOBILE_TWO = "+91 992 973 3743";
export const EMAIL_ADDRESS = "bnbturf@gmail.com";

export const FACEBOOK_LINK = "https://www.facebook.com/";
export const INSTAGRAM_LINK = "https://www.instagram.com/";
export const TWITTER_LINK = "https://www.twitter.com/";
export const WHATSAPP_LINK = "https://www.whatsapp.com/";

export const USER_ID = JSON.parse(localStorage.getItem("UserLogin"));
export const ADMIN_DATA = JSON.parse(localStorage.getItem("AdminLogin"));
export const ID_DETAILS = JSON.parse(localStorage.getItem("IDForDetails"));
export const EDIT_SALES = JSON.parse(localStorage.getItem("EditSalesEntry"));

export const ONLINE_ORDER_DETAILS = JSON.parse(
  localStorage.getItem("OnlineOrderDetails")
);
export const PAYMENT_DETAILS = JSON.parse(
  localStorage.getItem("PaymentDetails")
);
export const BOOKING_DETAILS = localStorage.getItem("BookingDetails");

export const SALT_KEY = "0038a2ff-6756-4b1b-882d-6bac32987fbf";
export const MERCHANT_ID = "URUVELAONLINE";
