import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "../comman/Loader";
import { loginUser } from "../../services/APIService";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await loginUser(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        localStorage.setItem("AdminLogin", JSON.stringify(response.data));
        navigate("/admin");
        window.location.reload();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div className="auth-bg">
      {isLoading && <Loader />}
      <div class="wrapper">
        <div id="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6 auth-box">
                <div class="proclinic-box-shadow">
                  <h3 class="widget-title">Login</h3>
                  {showMsg && (
                    <div
                      className={msgClass}
                      //class="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  )}
                  <form class="widget-form" onSubmit={handleSubmit(onSubmit)}>
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <input
                          placeholder="Contact"
                          class="form-control"
                          {...register("contact", {
                            required: "Enter contact no",
                          })}
                        />
                        {errors.contact && (
                          <span className="text-danger float-left">
                            {errors.contact.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <input
                          type="password"
                          placeholder="Password"
                          class="form-control"
                          {...register("pass", {
                            required: "Enter password",
                          })}
                        />
                        {errors.pass && (
                          <span className="text-danger float-left">
                            {errors.pass.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="form-check row">
                      <div class="col-sm-12 text-left">
                        <div class="custom-control custom-checkbox">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="ex-check-2"
                          />
                          <label class="custom-control-label" for="ex-check-2">
                            Remember Me
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="button-btn-block">
                      <button class="btn btn-primary btn-lg btn-block">
                        Login
                      </button>
                    </div>
                    <div class="auth-footer-text">
                      {/* <small>
                        New User, <a href="sign-up.html">Sign Up</a> Here
                      </small> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
