import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../comman/Loader";
import {
  getEntries,
  getExcelData,
  deleteEntry,
  getAllParty,
  generatePdf,
} from "../../services/APIService";
import ExportToExcel from "../../services/ExportToExcel";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Reports() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [salesData, setSalesData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [partyData, setPartyData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    loadAllParties();
  }, []);

  const loadAllParties = async () => {
    try {
      setIsLoading(true);
      var response = await getAllParty();
      setPartyData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      var response = await getEntries(data);
      for (let i = 0; i < response.length; i++) {
        response[i].formattedDate = moment(response[i].date).format(
          "DD/MM/YYYY"
        );
      }
      setSalesData(response);
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const exportToExcel = async (data) => {
    try {
      setIsLoading(true);
      var res = await getExcelData(data);
      var exportData = [];
      for (let i = 0; i < res.length; i++) {
        res[i].Date = moment(res[i].Date).format("DD/MM/YYYY");
        exportData.push({
          SrNo: i + 1,
          PartyName: res[i].parties.name,
          Date: res[i].date,
          PartyLocation: res[i].partyLocation,
          VehicleNo: res[i].vehicleNo,
          DriverNo: res[i].driverNo,
          CementType: res[i].cementType,
          CompanyRate: res[i].companyRate,
          "PartyRate(W.B)": res[i].partyRateWB,
          "PartyRate(I.B)": res[i].partyRateIB,
          "Req.MT/Bags": res[i].mtOrBags,
          OldPending: res[i].oldPending,
          TotalAmount: res[i].totalAmount,
          PaidAmount: res[i].paid,
          PaymentMode: res[i].paymentMode,
          TotalPending: res[i].totalPending,
          Remark: res[i].remark,
        });
      }
      ExportToExcel.exportToExcel(
        exportData,
        "Sales Report " + getValues("fromDate") + " - " + getValues("toDate")
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = (item) => {
    item.date = moment(item.date).format("YYYY-MM-DD");
    localStorage.setItem("EditSalesEntry", JSON.stringify(item));
    navigate("/admin");
    window.location.reload();
  };

  const onDelete = (item) => {
    console.log(item);
    setDeleteData(item);
  };

  const onPrint = (item) => {
    item.partyName = item.parties.name;
    generatePdf(item);
  };

  const deleteRecord = async () => {
    try {
      setIsLoading(true);
      const response = await deleteEntry(deleteData);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        var data = {
          partyId: getValues("partyId"),
          cementType: getValues("cementType"),
          fromDate: getValues("fromDate"),
          toDate: getValues("toDate"),
        };
        onSubmit(data);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="row no-margin-padding">
        <div className="col-md-6">
          <h3 className="block-title">Reports</h3>
        </div>
        <div className="col-md-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <a
                onClick={handleSubmit(exportToExcel)}
                href="javascript:void(0)"
              >
                Download Excel
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-area-2 proclinic-box-shadow">
              <h3 className="widget-title">Reports</h3>
              {showMsg && (
                <div
                  className={msgClass}
                  //className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{msgText}</strong>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label for="dob">Select Party</label>
                    <select
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      {...register("partyId", {
                        required: "Select party",
                      })}
                    >
                      <option value="">Select party</option>
                      <option value="All">All</option>
                      {partyData &&
                        partyData.map((item, i) => (
                          <option key={i} value={item.partyId}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {errors.partyId && (
                      <span className="text-danger float-left">
                        {errors.partyId.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label for="dob">Cement Type</label>
                    <select
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      {...register("cementType", {
                        required: "Select cement type",
                      })}
                    >
                      <option value="">Select Type</option>
                      <option value="All">All</option>
                      <option value="PPC">PPC</option>
                      <option value="OPC">OPC</option>
                      <option value="SUPER">SUPER</option>
                    </select>
                    {errors.cementType && (
                      <span className="text-danger float-left">
                        {errors.cementType.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label for="dob">From Date</label>
                    <input
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      {...register("fromDate", {
                        required: "Select from date",
                      })}
                    />
                    {errors.fromDate && (
                      <span className="text-danger float-left">
                        {errors.fromDate.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label for="dob">To Date</label>
                    <input
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      {...register("toDate", {
                        required: "Select to date",
                      })}
                    />
                    {errors.toDate && (
                      <span className="text-danger float-left">
                        {errors.toDate.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-2 mb-3">
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-primary btn-lg"
                    >
                      Show Report
                    </button>
                  </div>
                  <div className="form-group col-md-6 mb-3 float-right">
                    <button
                      type="button"
                      onClick={handleSubmit(exportToExcel)}
                      className="btn btn-primary btn-lg"
                    >
                      Export To Excel
                    </button>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="col-md-12">
                      <div className="widget-area-2 proclinic-box-shadow">
                        <h3 className="widget-title">Sales Entries</h3>
                        {salesData?.length > 0 ? (
                          <div className="table-div">
                            <div className="table-responsive">
                              <div className="scrollme">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Sr.No</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Location</th>
                                      <th scope="col">V.No / D.No</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">C.Rate</th>
                                      <th scope="col">Rate(W.B)</th>
                                      <th scope="col">Rate(I.B)</th>
                                      <th scope="col">MT/Bags</th>
                                      {/* <th scope="col">Old Pending</th> */}
                                      <th scope="col">Total</th>
                                      {/* <th scope="col">Paid</th>
                                      <th scope="col">Total Pending</th>
                                      <th scope="col">Remark</th> */}
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {salesData &&
                                      salesData.map((item, i) => (
                                        <tr key={i}>
                                          <th scope="row">{i + 1}</th>
                                          <td>{item.parties?.name}</td>
                                          <td>{item.formattedDate}</td>
                                          <td>{item.partyLocation}</td>
                                          <td>
                                            {item.vehicleNo} / {item.driverNo}
                                          </td>
                                          <td>{item.cementType}</td>
                                          <td>{item.companyRate}</td>
                                          <td>{item.partyRateWB}</td>
                                          <td>{item.partyRateIB}</td>
                                          <td>{item.mtOrBags}</td>
                                          {/* <td>{item.oldPending}</td> */}
                                          <td>{item.totalAmount}</td>
                                          {/* <td>{item.paid}</td>
                                          <td>{item.totalPending}</td>
                                          <td>{item.remark}</td> */}
                                          <td>
                                            <div className="btn-group">
                                              <button
                                                type="button"
                                                className="btn btn-info rounded"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                ...
                                              </button>
                                              <div className="dropdown-menu">
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0)"
                                                  onClick={() => onPrint(item)}
                                                >
                                                  Print
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0)"
                                                  onClick={() => onEdit(item)}
                                                >
                                                  Edit
                                                </a>
                                                <a
                                                  data-toggle="modal"
                                                  data-target="#deleteModal"
                                                  className="dropdown-item"
                                                  href="javascript:void(0)"
                                                  onClick={() => onDelete(item)}
                                                >
                                                  Delete
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <h6 className="my-3">No record found..!</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade proclinic-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        id="deleteModal"
      >
        <div className="modal-dialog modal-lorvens">
          <div className="modal-content proclinic-box-shadow2">
            <div className="modal-header">
              <h5 className="modal-title">
                Do you want to delete this record ?
              </h5>
              <span
                className="ti-close"
                data-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <button
                    onClick={deleteRecord}
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-lorvens proclinic-bg"
                  >
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
